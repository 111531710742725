<template>
    <div id="content" class="product-category-management" v-if="productCategoryRender">
        <div class="bar-actions">
            <TranslateButton :entityId="'product_category.' + productCategoryRender.id" :translationLangs="productCategoryRender.translations" @newTranslation="newTranslation" @deleteTranslation="deleteTranslation"></TranslateButton>
            <Button bType="cancel" :bCallback="goBack" bLabel="user.form.cancel"></Button>
            <Button bType="save" :bCallback="saveProductCategory" customClass="btn-action" :bLabel="$route.params.id ? 'menu_aux.action.save' : 'menu_aux.action.save'"></Button>
            <Button v-if="$route.params.id" bType="more" :bCallback="showMoreOptions"></Button>
            <div class="more-options" v-if="moreOptions">
                <Button bType="delete" :bCallback="showDeleteProductCategoryModal" bLabel="menu_aux.action.delete" customClass="btn-secondary"></Button>
            </div>
        </div>

        <div class="form-container" v-if="productCategoryRender">
            <div class="form">
                <div class="form-title">
                    <h1 class="form-title">{{ $t('user.form.basic_info') }}</h1>
                </div>
                <div class="label-container">
                    <div class="left-container">
                        <div class="user-answer">
                            <!-- <TranslateInput :translation_keyname="'product_category.' + productCategoryRender.id + '.name'" @input="updateValues($event)" v-model="productCategoryRender.name" :gOptions="{ mandatory: true }" :gPlaceholder="$t('product.product_category.name_placeholder')" :gLabel="$t('user.user_name')" :gMessage="{ error: $t('product.product_category.error_name') }" :gState="errors.name ? 'error' : ''" gSize="m"></TranslateInput> -->
                            <Input @input="updateValues($event)" v-model="productCategoryRender.name" :gOptions="{ mandatory: true }" :gPlaceholder="$t('product.product_category.name_placeholder')" :gLabel="$t('user.user_name')" :gMessage="{ error: $t('product.product_category.error_name') }" :gState="errors.name ? 'error' : ''" gSize="m"></Input>
                        </div>
                    </div>
                    <div class="right-container translate-hide">
                        <!-- SWITCH HERE PLS -->
                        <div class="switch">
                            <h2 class="label-form" v-html="productCategoryRender.status ? $t('sites.form.active') : $t('sites.form.inactive')"></h2>
                            <div class="switch-click" @click="popupStatus()">
                                <input class="switch-input-form" type="checkbox" v-model="productCategoryRender.status" disabled="true" />
                            </div>
                        </div>
                        <p class="description" v-html="$t('product.product_category.switch_desc')"></p>
                    </div>
                </div>
                <div class="label-container translate-hide">
                    <div class="left-container">
                        <h2 class="label-form">{{ $t('product.product_category.color') }}</h2>
                        <p v-if="errors.color" class="error-form" v-html="$t('product.product_category.color_error')"></p>
                        <div class="user-answer ball-colours">
                            <span class="ball" @click="setColor(col)" v-for="col in colors" :style="{ backgroundColor: col }" :class="{ active: productCategoryRender.color == col }" :key="col"></span>
                        </div>
                    </div>
                    <div class="right-container translate-hide">
                        <h2 class="label-form">{{ $t('product.product_category.order') }}</h2>
                        <p class="description" v-html="$t('product.product_category.order_desc')"></p>
                        <p v-if="errors.order" class="error-form" v-html="$t('product.product_category.order_error')"></p>
                        <div class="user-answer">
                            <input
                                type="number"
                                :value="productCategoryRender.order"
                                @input="
                                    (v) => {
                                        productCategoryRender.order = v.target.value
                                    }
                                "
                                @change="productCategoryRender = productCategoryRender"
                                class="input number"
                                autocomplete="off"
                                name="name"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'

export default {
    name: 'ProductCategoryManagement',
    props: { id: undefined },
    components: {},
    data() {
        return {
            sendForm: true,
            errors: {
                name: false,
                color: false,
                status: false,
                order: false
            },
            idCategory: false,
            form: false,
            colors: extraColors,
            moreOptions: false
        }
    },
    computed: {
        productCategoryRender: {
            get() {
                if (this.idCategory) {
                    var category = this.$store.getters['label/getCategories'](this.idCategory)
                    if (category.status == -2) {
                        category.status = 0
                    }
                    return category
                }
            },
            set(value) {
                this.$store.commit('label/updateProductCategory', value)
            }
        }
    },
    methods: {
        newTranslation(code) {
            this.$set(this.productCategoryRender.translations, code, { missing: 0, translated: 0 })
        },

        deleteTranslation(code) {
            this.$delete(this.productCategoryRender.translations, code)
        },
        // Method for make changes from form to store object
        updateValues(v) {
            // this.productCategoryRender.name = v
            this.productCategoryRender = this.productCategoryRender
        },
        showMoreOptions() {
            // METHOD TO SHOW OR HIDE MORE OPTION BUTTONS
            this.moreOptions = !this.moreOptions
        },
        onlyNumbers(event) {
            let val = event.target.value.trim()
            // It can only be positive integer or empty, and the rule can be modified according to the requirement.
            if (/^[0-9]\d*$|^$/.test(val)) {
                this.oldNum = val
            } else {
                event.target.value = ''
            }
        },
        setColor(color) {
            this.productCategoryRender.color = color
            this.updateValues(color)
        },

        popupStatus() {
            var self = this
            if (self.productCategoryRender.status == '1' || self.productCategoryRender.status == true) {
                self.$popup.confirm({
                    message: self.$t('product.product_category.popup_inactive'),
                    textSave: self.$t('product.product_category.popup_confirm'),
                    callSave: function () {
                        self.productCategoryRender.status = 0
                        self.updateValues(self.productCategoryRender.status)
                    },
                    callCancel: function () {
                        self.productCategoryRender.status = 1
                        self.updateValues(self.productCategoryRender.status)
                    }
                })
            } else {
                self.productCategoryRender.status = 1
                self.updateValues(self.productCategoryRender.status)
            }
        },

        load() {
            var self = this
            // Cargar categorías cuando se hace refresh en el formulario
            if (Object.values(self.$store.getters['label/getCategories']).length == 0) {
                self.$store.dispatch('label/loadCategories', {}).then(() => {
                    if (self.$route.name == 'ProductCategory') {
                        if (typeof self.id != 'undefined') {
                            self.$router.push({
                                name: 'EditProductCategory',
                                params: { id: self.id }
                            })
                            self.idCategory = self.id
                        } else {
                            self.$store.dispatch('label/addNewProductCategory').then((id) => {
                                self.idCategory = id
                                self.$router.push({ name: 'AddProductCategory' })
                            })
                        }
                    } else {
                        if (typeof self.id != 'undefined') {
                            self.idCategory = self.id
                        } else {
                            self.$store.dispatch('label/addNewProductCategory').then((id) => {
                                self.idCategory = id
                            })
                        }
                    }
                })
            } else {
                if (self.$route.name == 'ProductCategory') {
                    if (typeof self.id != 'undefined') {
                        self.$router.push({
                            name: 'EditProductCategory',
                            params: { id: self.id }
                        })
                    } else {
                        self.$store.dispatch('label/addNewProductCategory').then((id) => {
                            self.idCategory = id
                            self.$router.push({ name: 'AddProductCategory' })
                        })
                    }
                }
            }
        },

        goBack() {
            this.$overlay.show()
            this.$emit('back')
        },
        saveProductCategory() {
            var self = this
            self.$overlay.loading()
            this.$store.dispatch('label/validateProductCategory', this.idCategory).then((response) => {
                if (response.status) {
                    var sendForm = self.$route.params.id ? 'editProductCategory' : 'addProductCategory'
                    self.$store.dispatch('label/' + sendForm, self.idCategory).then((response) => {
                        self.goBack()
                        self.$snackbar.open({
                            message: self.$route.params.id ? self.$t('snackbar.edit_success') + ' <b>' + self.productCategoryRender.name + '</b>' : self.$t('snackbar.add_success') + ' <b>' + self.productCategoryRender.name + '</b>',
                            customClass: 'success',
                            duration: 5000
                        })
                    })
                } else {
                    self.errors = response.errors
                    self.$overlay.hide()
                    scrollError()
                }
            })
        },

        deleteProductCategory() {
            var self = this

            const snackBarData = {
                message: self.$t('snackbar.remove_success') + ' <b>' + self.productCategoryRender.name + '</b>',
                customClass: 'second',
                duration: 5000
            }

            self.$store.dispatch('label/removeCategory', self.productCategoryRender.id).then(function (response) {
                self.$snackbar.open(snackBarData)
                self.$emit('back')
            })
        },

        showDeleteProductCategoryModal() {
            var self = this
            const modalMessage = self.$t('product.product_category.popup_delete') + self.productCategoryRender.name + "'? " + '</br>' + self.$t('product.product_state.popup_delete_advice')

            this.$popup.delete({ message: modalMessage, callSave: this.deleteProductCategory })
        }
    },
    created() {
        this.load()
    },
    mounted() {},
    watch: {},
    updated() {}
}
</script>

<style lang="scss">
#content.product-category-management {
    .left-container {
        padding-left: 0;
    }
}
</style>
